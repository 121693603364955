<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParam" :model="pageParam" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005688')" prop="categoryId">
              <Cascader
                class="category_select_box"
                v-model="pageParam.categoryId"
                :load-data="loadCategoriesData"
                :data="categoriesList"
                filterable
                change-on-select>
              </Cascader>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1002662')" prop="price">
              <div class="flex align-items-center">
                <InputNumber :min="0" v-model="pageParam.priceStart" :formatter="value => `${parseInt(value)}`" style="width: 90px;"></InputNumber>
                <span class="ml12 mr12">-</span>
                <InputNumber v-model="pageParam.priceEnd" :formatter="value => `${parseInt(value)}`" style="width: 90px;"></InputNumber>
              </div>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1002663')" prop="totalEpScore">
              <Select v-model="pageParam.totalEpScore" transfer>
                <Option v-for="item in totalEpScoreList" :key="item.value" :value="item.value">{{ item.title }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1002664')" prop="getRate24">
              <Select v-model="pageParam.getRate24" transfer>
                <Option v-for="item in getRate24List" :key="item.value" :value="item.value">{{ item.title }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1002665')" prop="getRate48H">
              <Select v-model="pageParam.getRate48H" transfer>
                <Option v-for="item in getRate48HList" :key="item.value" :value="item.value">{{ item.title }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :xxl="twoItemCol" :xl="twoItemCol" :lg="twoItemCol" :md="oneItemCol">
            <CheckboxGroup v-model="pageParam.typeList" class="flex align-items-center ml25 mt7">
              <Checkbox class="mr35" v-for="(item, index) in typesData" :key="index" :label="item.value">{{ item.title }}</Checkbox>
            </CheckboxGroup>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1002666')" prop="keyword">
              <Input v-model.trim="pageParam.keyword" clearable></Input>
            </Form-item>
          </Col>
          <Col style="width: 200px;" push="1">
            <Button @click="reset" icon="md-refresh" class="mr10">{{ $t('key1000094') }}</Button>
            <Button type="primary" @click="search()" icon="md-search">{{ $t('key1000269') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box" v-if="tableList.length >0">
      <!--批量采集-->
      <Button type="primary" class="mr15" @click="batchCollectionBtn" :disabled="!batchCollectionTalg">{{ $t('key1005689') }}</Button>
    </div>
    <!--列表区域-->
    <div class="findSimilarProducts_box" ref="scrollContainer" @scroll="handleScroll">
      <template v-if="tableList.length >0">
        <div class="findSimilarProducts_box_item" v-for="(item,index) in tableList" :key="index">
          <!--勾选框-->
          <Checkbox class="collection_box" size="large" v-model="item.collectionValue"></Checkbox>
          <!--图片-->
          <div class="imageUrl_box" @click="toAlibabaBtn(item)">
            <img v-if="item.imgUrl" :src="item.imgUrl" alt="" :onerror="errorPlaceholder">
            <img v-else :src="failPlaceholder" alt=""/>
          </div>
          <!--金额-->
          <div class="price_box">
            <span class="font-size-17 font-weight-bold" style="color: #E80301;">{{ '￥ ' + item.minPrice + $t('key1002667') }}</span>
            <div class="flex align-items-center font-size-12 color-333">
              <span>{{ $t('key1005690') }}</span>
              <span class="distributorThemeColor font-weight-bold">{{ item.salesCnt90d }}</span>
            </div>
          </div>
          <!--商品标题-->
          <div class="title_box ellipsis_2" :title="item.title" @click="toAlibabaBtn(item)">{{ item.title }}</div>
          <!--标签-->
          <div class="flex align-items-center mt8 pl12 pr12 flex-wrap ">
            <template v-if="item.serviceList && item.serviceList.length >0">
              <span class="serviceNameStyle mr8 mb8" v-for="ele in item.serviceList">{{ ele.name }}</span>
            </template>
          </div>
        </div>
        <!--没有更多数据了-->
        <div class="noMoreData" v-if="tableList.length>=total">{{ $t('key1000522') }}</div>
      </template>
      <!--暂无数据-->
      <div v-else class="no_classify">
        <img :src="noContent" width="250" height="250" :alt="$t('key1000089')">
      </div>
    </div>
  </div>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import {commonCopyContent, isValueEmpty, setConfigCharacters} from "@/utils/common";

export default {
  name: 'collectProducts',
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      pageParam: {
        categoryId: [], // 1688类目
        keyword: '',// 搜索关键词
        priceEnd: null,
        priceStart: null,
        totalEpScore: 'null', // 综合评分
        getRate24: 'null', // 24小时揽收率
        getRate48H: 'null', // 48小时揽收率
        typeList: [],
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      tableList: [],
      errorPlaceholder: "this.onerror='';this.classList.add('error-image'); this.src='" + require('@/assets/images/imageError.jpg') + "'",
      failPlaceholder: require('@/assets/images/imageFail.jpg'),
      noContent: require('@/assets/images/noDataIcon.png'),
      categoriesList: [],
      totalEpScoreList: [
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002671'), value: 'null'},
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002672'), value: 'totalEpScoreLv1'},
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002673'), value: 'totalEpScoreLv2'},
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002674'), value: 'totalEpScoreLv3'},
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002675'), value: 'totalEpScoreLv4'},
      ],
      getRate24List: [
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002671'), value: 'null'},
        {title: '<95%', value: 'getRate24HLv1'},
        {title: '>=95%', value: 'getRate24HLv2'},
        {title: '>=99%', value: 'getRate24HLv3'}
      ],
      getRate48HList: [
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002671'), value: 'null'},
        {title: '<95%', value: 'getRate48HLv1'},
        {title: '>=95%', value: 'getRate48HLv2'},
        {title: '>=99%', value: 'getRate48HLv3'}
      ],
      typesData: [
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002676'), value: 'certifiedFactory'},
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002677'), value: 'shipInToday'},
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002678'), value: 'shipIn24Hours'},
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002679'), value: 'shipIn48Hours'},
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002680'), value: 'noReason7DReturn'},
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002681'), value: 'isOnePsale'},
        {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002682'), value: 'isOnePsaleFreePost'}
      ],
    }
  },
  mounted() {
    this.$refs['scrollContainer'].addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.$refs['scrollContainer'].removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    batchCollectionTalg() {
      let talg = false;
      if (this.tableList.length > 0) {
        talg = this.tableList.some((item) => {
          return item.collectionValue
        })
      }
      return talg;
    }
  },
  created() {
    this.initWebSocket();
    this.getCategoryList();
    this.search();
  },
  methods: {
    // 处理1688类目的数据
    loadCategoriesData(item, callback) {
      let v = this;
      item.loading = true;
      let query = {
        siteId: '',
        platformId: 'alibaba',
        parentCategoryId: item.value,
        rootCategoryId: null,
        level: item.level.toString()
      }
      let newList = [];
      v.axios.post(api.post_listing_queryCategory, query).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              let ele = data[i];
              let obj = {
                ...ele,
                value: ele.categoryId,
                label: ele.categoryId + ' - ' + ele.categoryName,
                level: item.level + 1
              };
              if (ele.isLeaf === 'N') {
                obj.children = [];
                obj.loading = false;
              }
              newList.push(obj)
              if (i === data.length - 1) {
                item.children = newList;
                item.loading = false;
              }
            }
            callback();
          }
          if (data.length === 0) {
            item.loading = false;
            v.$Message.warning(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000116'));
            callback();
          }
        } else {
          item.loading = false;
          item.children = [];
          callback();
        }
      }).catch(() => {
        item.loading = false;
        item.children = [];
        callback();
      });
    },
    // 获取1688类目的数据
    getCategoryList() {
      let v = this;
      let query = {
        parentCategoryId: '',
        rootCategoryId: '',
        platformId: 'alibaba',
        siteId: null
      }
      v.axios.post(api.post_listing_queryCategory, query).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          if (data.length > 0) {
            data.map((item) => {
              item.value = item.categoryId;
              item.label = item.categoryId + ' - ' + item.categoryName;
              if (item.isLeaf === 'N') {
                item.children = [];
                item.level = 2;
                item.loading = false;
              }
            });
          }
          v.categoriesList = data;
        }
      });
    },
    // 重置按钮
    reset() {
      let v = this;
      v.$refs['pageParam'].resetFields();
      v.pageParam.priceStart = null;
      v.pageParam.priceEnd = null;
      v.pageParam.typeList = [];
      for (let key in v.pageParam) {
        if (['totalEpScore', 'getRate24', 'getRate48H'].includes(key)) {
          v.pageParam[key] = 'null';
        }
      }
      v.getList();
    },
    // 查询按钮
    search() {
      this.tableList = [];
      this.pageParam.pageNum = 1;
      this.getList();
    },
    // 参数处理
    handleParameter() {
      let v = this;
      let {totalEpScore, getRate24, getRate48H, typeList, categoryId, ...obj} = v.pageParam;
      let list = [];
      for (let key in v.pageParam) {
        if (['totalEpScore', 'getRate24', 'getRate48H'].includes(key)) {
          if (v.pageParam[key] !== 'null') {
            list.push(v.pageParam[key])
          }
        }
      }
      if (typeList.length > 0) {
        list.push(...typeList)
      }
      obj.filters = list || [];
      obj.categoryId = categoryId.length > 0 ? categoryId[categoryId.length - 1] : null;
      return obj;
    },
    // 列表查询的方法
    getList() {
      let v = this;
      if (!isValueEmpty(v.pageParam.priceStart) && !isValueEmpty(v.pageParam.priceEnd)) {
        if (v.pageParam.priceStart <= v.pageParam.priceEnd) {
          handleList()
        } else {
          v.$Message.warning(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002685'));
          return false;
        }
      } else {
        handleList();
      }

      function handleList() {
        v.tableLoading = true;
        v.axios.post(api.post_productInfo_alibabaProductQuery, v.handleParameter()).then(response => {
          v.tableLoading = false;
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data) {
              v.total = data.pageInfo.totalRecords;
              let newList = data.result || [];
              if (newList.length > 0) {
                newList.map((item) => {
                  item.collectionValue = false;
                });
              }
              v.tableList = [...v.tableList, ...newList];
              v.$forceUpdate();
            }
          }
        });
      }
    },
    // 滚动到底部加载更多
    handleScroll() {
      const container = this.$refs.scrollContainer;
      const scrollHeight = container.scrollHeight;
      const scrollTop = container.scrollTop;
      const clientHeight = container.clientHeight;
      // 判断是否滚动到距离底部50px，并且不在加载数据的状态
      if (this.tableList.length < this.total) {
        if ((scrollTop + clientHeight >= scrollHeight - 100) && !this.tableLoading) {
          this.pageParam.pageNum += 1;
          this.getList();
        }
      }
    },
    // 跳转到1688平台
    toAlibabaBtn(item) {
      let href = `https://detail.1688.com/offer/${item.itemId}.html`;
      window.open(href, '_blank');
    },
    // 批量采集
    batchCollectionBtn() {
      let itemIds = [];
      this.tableList.map((item) => {
        if (item.collectionValue && item.itemId) {
          itemIds.push(item.itemId);
        }
      });
      if (itemIds.length > 0) {
        this.axios.post(api.post_productInfo_gathers, itemIds, {loading: true, loadingText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000212')}).then((res) => {
          this.tableLoading = false;
          if (res.data.code === 0) {
            this.$Message.info(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005691'));
            this.tableList.map((item) => {
              item.collectionValue = false;
            });
            this.$forceUpdate();
          }
        });
      }
    },
    // 初始化 Websocket(实时获取同步1688平台商品的状态)
    initWebSocket() {
      let v = this;
      v.$webSocket.socket.onmessage = function (e) {
        v.$webSocket.heartReset().heartStart();
        let syncInfo = '';
        if (e) {
          try {
            syncInfo = JSON.parse(e.data);
          } catch (e) {
            syncInfo = null;
          }
          if (syncInfo) {
            if (syncInfo.type === 'sync') {
              let data = syncInfo.data;
              let errorCode = data.errorCode;
              // 0接口请求失败 1 部分匹配失败 2 全部匹配失败 3 匹配成功
              let errorCodeList = [
                {
                  errorCode: 0,
                  type: 'error',
                  title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005678'),
                  text1: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1004800') + data.message
                },
                {
                  errorCode: 1,
                  type: 'warning',
                  title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005679'),
                  optionsText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005680'),
                  text1: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005681'),
                  text2: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005671')}`,
                  optionType: 'copy',
                  copyData: data.failSpuList ? data.failSpuList.join('、') : ''
                },
                {
                  errorCode: 2,
                  type: 'error',
                  title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005678'),
                  text1: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005672')}${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005671')}`
                },
                {
                  errorCode: 3,
                  type: 'success',
                  title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005682'),
                  optionsText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005683'),
                  text1: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005684'),
                  text2: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005671')}`,
                  optionType: 'click'
                },
                {
                  errorCode: 4,
                  type: 'error',
                  title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005678'),
                  text1: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005673')}`
                },
              ];
              errorCodeList.map((item) => {
                if (item.errorCode === errorCode) {
                  v.asynchronousNotice(item);
                }
              })
            }
          }
        }
      };
    },
    // 异步获取商品的notice通知提醒
    asynchronousNotice(data) {
      let v = this;
      v.$Notice[data.type]({
        name: 'alibabaProduct', // 标识
        title: data.title,
        duration: 10,
        render(h, params) {
          return h('div', {
            style: {
              display: 'flex',
              flexWrap: 'wrap',
              lineHeight: '18px',
            }
          }, [
            h('span', data.text1),
            data.optionsText ? h('Button', {
              props: {
                type: 'text'
              },
              style: {
                color: '#2D8CF0',
                display: 'inline-block',
                padding: 0,
                marginLeft: '3px',
                height: 0,
                lineHeight: '15px',
                border: 'none !important',
                boxShadow: 'initial !important',
                alignItems: 'baseline',
                fontSize: '14px'
              },
              on: {
                click: () => {
                  // 查看列表
                  if (data.optionType === 'click') {
                    let path = v.$route.path;
                    if (path === '/productCenter/productGoods') {
                      localStorage.setItem('1688ProductTalg', JSON.stringify(true));
                      setTimeout(() => {
                        v.$emit('updateData', '1688ProductTalg');
                      }, 500);
                    } else {
                      localStorage.setItem('1688ProductTalg', JSON.stringify(true));
                      setTimeout(() => {
                        v.$router.push('/productCenter/productGoods');
                      }, 500);
                    }
                  }
                  // 复制失败SPU
                  else {
                    if (data.copyData) {
                      commonCopyContent(data.copyData, alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000930'));
                    }
                  }
                  v.$Notice.close('alibabaProduct');
                }
              }
            }, data.optionsText) : '',
            data.text2 ? h('span', data.text2) : '',
          ]);
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>
.findSimilarProducts_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: 700px;
  overflow-y: auto;

  .findSimilarProducts_box_item {
    flex: 1;
    max-width: 305px;
    min-height: 430px;
    border-radius: 5px;
    background-color: #fff;
    margin: 10px;
    box-sizing: border-box;
    text-align: center;
    padding-bottom: 10px;
    position: relative;

    .collection_box {
      position: absolute;
      top: 9px;
      left: 9px;
      z-index: 10;
      cursor: pointer;
    }

    .imageUrl_box {
      padding-top: 20px;

      img {
        width: 270px;
        height: 270px;
        object-fit: cover;
        display: inline-block;
      }
    }

    .price_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'PingFangSC-Regular';
      margin-top: 5px;
      padding: 0 12px;
    }


    .title_box {
      color: #000;
      font-size: 14px;
      font-family: 'PingFangSC-Regular';
      margin-top: 5px;
      text-align: left;
      padding: 0 12px;
      font-weight: bold;
    }

    .serviceNameStyle {
      padding: 1px 7px;
      border: 1px solid #02A7F0;
      color: #02A7F0;
      font-size: 12px;
      border-radius: 5px;
    }

    .scoreStyle {
      color: #FF6600;
      font-size: 12px;
      font-family: 'PingFangSC-Regular';
      font-weight: bold;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1500);
    }
  }

  .no_classify {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

    .txt {
      font-size: 15px;
      color: #666;
      margin-top: 15px;
    }
  }

  .noMoreData {
    color: #666;
    font-size: 14px;
    padding: 10px 0;
    text-align: center;
    width: 100%;
  }
}
</style>
